<script>
import Layout from '../../layouts/main';
import Product from '@/components/widgets/loading-product';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'Auction': 'Leilão',
      'Bid': 'Dar lance',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Auction': 'Leilão',
      'Bid': 'Dar lance',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, Product },
  data() {
    return {
      products: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      }
    };
  },
  methods: {
    getProducts() {
      api
        .get('auction')
        .then((response) => {
          if (response.data.status === 'success') {
            this.products.list = response.data.list
            this.products.loading = false
          }
        })
        .catch(error => {
          this.products.errored = error
          this.products.loading = false
        })
        .finally(() => {
          this.products.loading = false
        })
    }
  },
  mounted() {
    this.getProducts();
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Auction') }}</h4>
        </div>
      </div>
    </div>
    <div v-if="products.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
    <div v-else-if="products.empty">{{ t('No records found.') }}</div>
    <div v-else-if="products.loading" class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!products.list || products.list && products.list.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum produto disponível
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-4" v-for="(product,id) in products.list" :key="id">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <div class="mb-3">
              <img :src="product.image" class="w-100" alt="">
            </div>
            <p>
              {{ product.name }}<br>
              {{ product.price }}
            </p>
            <p class="text-uppercase font-size-13 m-0">Custo por lance de {{ product.cost }}</p>
            <p class="text-uppercase font-size-13">Lances permitidos até {{ product.date.end }}</p>
            <router-link tag="a" :to="'/auction/' + product.id" class="text-dark">
             <button class="btn btn-danger btn-block text-uppercase">{{ t('Bid') }}</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>